import React from "react"
import * as Icon from "react-feather"

const contactItem = [
  { heading: `USA – New York`, para: (<address>Atyeti Inc<br />295 Madison Avenue Ste 1215, 12th Floor<br />New York, NY, 10017-6379</address>) },
  { heading: `USA – Virginia`, para: (<address>Atyeti Inc<br />11710 Plaza America Drive Suite 120<br />Reston, VA, 20190-4737</address>) },
  { heading: `USA – North Carolina`, para: (<address>Atyeti Inc<br />7001 Weston Parkway Suite 170<br />Cary, NC, 27513-2125</address>) },
  { heading: `USA - Texas`, para: (<address>Atyeti Inc<br />5345 Towne Square Drive, Suite 150<br />Plano, Texas, 75024-2448</address>)},
  { heading: `United Kingdom`, para: (<address>Atyeti Limited<br />46 New Broad Street<br />London, EC2M 1JH</address>) },
  { heading: `India - Trivandrum`, para: (<address>Atyeti IT Services Pvt Ltd<br />Module No.7 Basement Floor Park Centre Building Phase-I Campus, Kazhakuttom<br />Trivandrum, Kerala, 695581</address>) },
  { heading: `India – Pune`, para: (<address>Atyeti IT Services Pvt Ltd<br />Level 9, Tower No 2, S. No. 1, World Trade Centre, Kharadi<br />Pune, Maharashtra, 411014</address>) },
  { heading: `India – Hyderabad`, para: (<address>Atyeti IT Services Pvt Ltd<br />We Work, 6TH Floor, Krishe Emerald, Kondapur Main Road, Laxmi Cyber City, Whitefield, Kondapur<br />Hyderabad, Telangana, 500081</address>) },
  { heading: `India - Bengaluru`, para: (<address>Atyeti IT Services Pvt Ltd<br />Level 8, Prestige Technostar, B3 Building, Doddanakundi Industrial Area 2, Phase 1, Brookefield, Doddanekkundi<br />Bengaluru, Karnataka, 560048</address>) },
  { heading: `India - Chennai`, para: (<address>Atyeti IT Services Pvt Ltd<br />Dot Cowork-Tidel Park , Module No. 115 , 1ST Floor , No.4 Rajiv Gandhi, Tharamani<br />Chennai, Tamil Nadu, 600113</address>) },
  { heading: `Singapore`, para: (<address>Atyeti Pte Ltd<br />101 Cecil Street, #20-08 Tong Eng Building<br />Singapore, 069533</address>) },
  { heading: `Poland`, para: (<address>Atyeti Inc<br />Grabarska I, Pegaz Building B 3rd Floor Office 304<br />Wrocław, 50079</address>) },
  { heading: `Switzerland`, para: (<address>Atyeti Inc<br />Zurich City Center, Dreikönigstrasse 31a<br />Zürich, 8002</address>) },
  { heading: `Canada`, para: (<address>Atyeti IT Consulting Corporation<br />545 King Street West<br />Toronto, ON, M5V 1M1</address>) },
  { heading: `Hong Kong`, para: (<address>Atyeti Limited<br />Unit 706, 7F, Tower2 South Seas Centre, 75 Mody Road, Tsim Sha Tsui East, Tsim Sha Tsui<br />Kowloon</address>) },
  { heading: `Ireland`, para: (<address>Atyeti Limited<br />Ground Floor, 71 Baggot Street Lower<br />Dublin, D02 P593</address>) },
  { heading: `Malaysia`, para: (<address>Atyeti Sdn Bhd<br />Level 35-02 East Wing, Q Sentral, 2A, Jalan Stesen Sentral 2, Kuala Lumpur Sentral<br />Kuala Lumpur, 50470</address>) },
  { heading: `Colombia`, para: (<address>Atyeti Inc<br />Carrera 9 #71 – 70 of 303<br />Bogotá</address>) }
]

const ContactInfo = () => {
  return (
    <div className="contact-info-area pt-80">
      <div className="container">
        <div className="row justify-content-center">
          {contactItem.map((item, idx) => (
            <div className="col-lg-4 col-md-6 col-sm-6 pb-50" key={idx}>
              <div className="contact-info-box">
                <div className="icon">
                  <Icon.MapPin />
                </div>
                <h3>{item.heading}</h3>
                {item.para}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
