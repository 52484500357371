import React from "react"
import * as Icon from "react-feather"


const ContactForm = () => {
  const url = typeof window !== "undefined" ? window.location.hostname : ""
  return (
    <div className="contact-area pb-80 pt-160">
      <div className="container">
        <div className="section-title">
          <h2>Get In Touch With Us</h2>
          <div className="bar"></div>
        </div>
        <div className="row align-items-center d-flex justify-content-center">
      
          <div className="col-lg-6 col-md-12">
            <div className="col-lg-12 ">
              <div className="contact-info-box">
                <div className="icon">
                  <Icon.Mail />
                </div>

                <p className="pt-2">
                  Email:{" "}
                  <a href="mailto:atyeti-info@atyeti.com">
                    atyeti-info@atyeti.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-12  pt-3">
              {url === "atyeti.co.uk" ? (
                <div className="contact-info-box">
                  <div className="icon1">
                    <Icon.MapPin />
                  </div>

                  <h3>UK Headquarters</h3>
                  <p>
                    Atyeti Limited, Liverpool St.
                    <br />
                    46 New Broad Street
                    <br />
                    London EC2M 1JH
                    <br />
                    <a href="tel:+4402071170024">+44 02071170024</a>
                  </p>
                </div>
              ) : (
                <div className="contact-info-box">
                  <div className="icon1">
                    <Icon.MapPin />
                  </div>

                  <h3>USA – Princeton (Global Headquarters)</h3>
                  <p>
                    Atyeti Inc,
                    <br />
                    125 Village Blvd, Suite 320
                    <br />
                    Princeton NJ 08540
                    <br />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
