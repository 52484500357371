import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import ContactInfo from "../components/Contact/ContactInfo"
import ContactForm from "../components/Contact/ContactForm"

const Contact = () => (
  <Layout>
    <Seo
      title="Contact "
      description="Email us directly at atyeti-info@atyeti.com. Our team of experts is here to help. Contact us to start your smooth, secure, and cost-effective digital journey."
      keyword="contact, offices, get in touch, atyeti it services, princeton, new jersey, india"
    />

    <Navbar />
    <ContactForm />
    <ContactInfo />
    <Footer />
  </Layout>
)

export default Contact
